import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from "@/views/404";

Vue.use(VueRouter)

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual'
}

const routes = [{
    path: '/',
    name: 'home',
    component: () => import( /* webpackChunkName: "home" */ '../views/home'),
  },
  {
    path: `/base`,
    name: 'base',
    component: () => import( /* webpackChunkName: "base" */ '../views/base')
  },
  {
    path: `/cart`,
    name: 'cart',
    component: () => import( /* webpackChunkName: "cart" */ '../views/cart')
  },
  {
    path: `/orders`,
    name: 'orders',
    component: () => import( /* webpackChunkName: "orders" */ '../views/orders')
  },
  {
    path: `/clients`,
    name: 'clients',
    component: () => import( /* webpackChunkName: "clients" */ '../views/clients')
  },
  {
    path: `/client/:code`,
    name: 'client',
    component: () => import( /* webpackChunkName: "client" */ '../views/client'),
    props: true
  },
  {
    path: `/print`,
    name: 'print',
    component: () => import( /* webpackChunkName: "print" */ '../views/print')
  },

  {
    path: `/logout`,
    name: 'logout',
    component: () => import( /* webpackChunkName: "services" */ '../views/logout')
  },


  {
    path: "*",
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router