<template>
  <div class="status" :class="{
      'error': activeClass == 'error',
      'complete': activeClass == 'complete',
      'warning': activeClass == 'warning',
    }">
    <div v-if="header" class="status__header" >{{header}}</div>
    <div class="status__message">{{message}}</div>
  </div>
</template>



<script>

    export default {
        name: 'status-bar',

        data(){
            return {
                header: '',
                message: '',
                activeClass: '',
                timeOut: 5000
            }
        }, 

        created() {
          this.$bus.on('status', (data) => {

              if(data.msg) this.msg(data)
              if(data.err) this.err(data)

          })
        },

        methods: {
          msg(data) {

            this.close()

            this.activeClass = 'complete'
            this.message = data.msg;

            setTimeout(() => { this.close() }, this.timeOut)
          },

          err(data) {

            this.close()

            this.activeClass = 'error'
            this.message = data.err;

            setTimeout(() => { this.close() }, this.timeOut)
          },

          close() {
            this.activeClass = false;
          }
        }
    }

</script>

<style src="./style.scss" lang="scss" />