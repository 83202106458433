<template>
  <div id="app">

    <top-bar v-if="isDisabledHeader()" />
   
    <main class="main">
      <router-view/>
    </main>

    
     
    <status-bar ref="statusBar"></status-bar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { debounce } from 'lodash'

import TopBar from "@/components/top-bar";


export default {
  name: 'app',
  components: {TopBar},
  data () {
    return {
      isPositionFixed: false,
      isModalOpened: false,
      isDarkMode: null,
      modalProps: {
        type: Object,
        default: () => ({})
      },
    }
  },
  watch: {
    isPositionFixed: function () {
      this.isPositionFixed ? this.$bus.emit('set-position-fixed') : this.$bus.emit('remove-position-fixed')
    },
    '$route' (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$bus.emit('close-mobile-menu')
      }

      this.setHistoryLengthIfRouteChange({
        newRoute: newValue,
        oldRoute: oldValue,
        value: 1
      })
    },
  },

  created() {
    this.setHistoryStartValues()
    this.toggleTheme()

    window.addEventListener('resize', this.onResize, { passive: true })
    this.changeSizeFlag()

    //console.log(this.$refs, 'refs')
  },

  mounted () {

    
    
    this.$bus.on('set-position-fixed', () => {
      this.isPositionFixed = true
      document.querySelector('body').classList.add('overflow--hidden')
    })

    this.$bus.on('remove-position-fixed', () => {
      this.isPositionFixed = false
      document.querySelector('body').classList.remove('overflow--hidden')
    })

    this.$bus.on('open-mobile-menu', () => {
      this.openMenu()
      this.$bus.emit('set-position-fixed')
    })

    this.$bus.on('close-mobile-menu', () => {
      this.closeMenu()
      this.$bus.emit('remove-position-fixed')
    })

    this.startHistoryLength = 0

    
  },

  computed: {
    ...mapGetters(['isDesktop']),
    ...mapGetters(['isMenuOpened']),
    ...mapGetters(['getOpenedModals']),
    ...mapGetters(['isDarkModeOn'])
  },

  methods: {
    ...mapActions(['changeSizeFlag']),
    ...mapActions(['setHistoryStartValues', 'setHistoryLengthIfRouteChange']),
    ...mapActions(['openMenu', 'closeMenu']),

    closeMobileMenu() {
      this.$bus.emit('close-mobile-menu')
    },

    toggleTheme () {
      this.isDarkModeOn
          ? document.documentElement.classList.add('dark-mode')
          : document.documentElement.classList.remove('dark-mode')
    },

    onResize: debounce(function () {
      if (this.windowWidth !== window.innerWidth) {
        this.windowWidth = window.innerWidth
        this.changeSizeFlag()
      }
    }, 500),

    isDisabledHeader() {

      let pages = [
        'home',
        'print',
      ];

      return pages.includes(this.$router.currentRoute.name) ? false : true

    }

  }
}

</script>

<style lang="scss">
@import "assets/scss/main.scss";
</style>