import axios from "axios";
import config from "@/config";


const state = {
    orders: []
};

const getters = {
    getOrders(state) {
        return state.orders
    }
}

const mutations = {
    setOrder(state, data) {
        state.orders.push(data)
    },

    setOrders(state, data) {
        state.orders = data
    }
};

const actions = {

    sendOrdersStore(context, data) {
        axios.post(config.api + 'add-order', data)
            .then(response => {
                context.commit('setOrder', response.data)
                context.dispatch('cleanCartStore')
                context.dispatch('removeActiveClientStore')

            })
            .catch(error => {
                console.error(error);
            })

    },

    fetchOrders(context, data) {
        axios.post(config.api + 'get-orders', {
                manager_id: data.id
            })
            .then(response => {
                context.commit('setOrders', response.data)
            })
            .catch(() => {
                context.commit('setOrders', {})
            })

    },

    fetchOrderDetails(context, data) {
        return axios.post(config.api + 'get-order-items-by-order', {
            order_id: data.id
        })


    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};