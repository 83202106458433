var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({ref:"bottomSheet",class:[
    'bottom-sheet',
    {
      opened: _vm.opened,
      closed: _vm.opened === false,
      moving: _vm.moving
    }
  ],style:({ 'pointer-events': _vm.backgroundClickable && _vm.clickToClose === false ? 'none' : 'all' })},_vm.handlers),[_c('transition',{attrs:{"name":"fade"}},[(_vm.overlay)?_c('div',_vm._g({ref:"bottomSheetBackdrop",staticClass:"bottom-sheet__backdrop",style:({ 'background': _vm.overlayColor })},_vm.handlers)):_vm._e()]),_c('div',{ref:"bottomSheetCard",class:[
      'bottom-sheet__card',
      { stripe: _vm.stripe, square: !_vm.rounded },
      _vm.effect
    ],style:(_vm.cardStyle)},[_c('div',{ref:"pan",staticClass:"bottom-sheet__pan",on:{"mouseup":_vm.close}},[_c('div',{staticClass:"bottom-sheet__bar"})]),(_vm.hasScroll)?_c('div',{directives:[{name:"bar",rawName:"v-bar"}],ref:"bottomSheetCardContent",staticClass:"bottom-sheet__content"},[_c('div',{staticClass:"bottom-sheet__scroll"},[_c('div',{staticClass:"bottom-sheet__content-inner"},[_c('div',{staticClass:"bottom-sheet__container"},[_vm._t("default",null,{"isBottomSheetOpened":_vm.opened})],2)])])]):_c('div',{ref:"bottomSheetCardContent",staticClass:"bottom-sheet__content"},[_vm._t("default",null,{"isBottomSheetOpened":_vm.opened})],2),_vm._t("bottom-fixed")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }