let configStorage = localStorage.getItem('userconfig') ? JSON.parse(localStorage.getItem('userconfig')) : {}

const state = {
    //default
    currency: 'USD',


    // load from storage
    ...configStorage
}

const getters = {
    getUserConfig(state) {
        return state
    }
}

const mutations = {
    setCurrency(state, data) {
        state.currency = data
        localStorage.setItem('userconfig', JSON.stringify(state))
    }
}

const actions = {
    changeCurrencyStorage(context, data) {
        context.commit('setCurrency', data)
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
};