import axios from "axios";
import config from "@/config";

const state = {
    categoies: []
};

const getters = {
    getCategoies(state) {
        return state.categoies
    }
}

const mutations = {

    setCategories(state, data) {
        state.categoies = data
    }

};

const actions = {



    fetchCategory(context) {
        axios.get(config.api + 'categories')
            .then(response => {
                context.commit('setCategories', response.data)
            })
            .catch(error => {
                console.error(error.response.data.message);
            })
            .finally(() => (console.log('finaly')));
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};