import Vue from 'vue'
import Vuex from 'vuex'
import breakpoints from "@/store/modules/breakpoints";
import goBack from "@/store/modules/goBack";
import menu from "@/store/modules/menu";
import overlay from "@/store/modules/overlay";
import modals from "@/store/modules/modals";
import darkmode from "@/store/modules/darkmode";
import base from "@/store/modules/base";
import category from "@/store/modules/category";
import clients from "@/store/modules/clients";
import orders from "@/store/modules/orders";
import userConfig from "@/store/modules/userConfig";


Vue.use(Vuex)



export default new Vuex.Store({
  state: {

  },

  getters: {
    mobileMenuLinks: state => {
      return state.mobileMenuLinks;
    },
  },

  mutations: {},
  actions: {},
  modules: {
    breakpoints,
    goBack,
    menu,
    overlay,
    modals,
    darkmode,
    base,
    category,
    clients,
    orders,
    userConfig
  }
})