import axios from "axios";
import config from "@/config";

const errorHandler = (error) => {
    console.error(error.response.data.error);

    switch (error.response.status) {

        case 404:
            alert('404: В выбранном прайсе товаров нет');
            break;
        case 429:
            alert('429: Слишком много запросов к базе');
            break;
    }
}

const state = {
    products: []
};

const getters = {
    getCatalog(state) {
        return state.products
    }


}

const mutations = {

    changeCountMut(state, data) {
        let total = Number(state.products[data.index].count);

        state.products[data.index].count = total + data.count
    },

    setCatalog(state, data) {
        //таймаут что б не тормозила анимация
        setTimeout(() => {
            state.products = data
        }, 200)
    },

    setCountDec(state, data) {
        for (let key in state.products) {
            if (state.products[key].id == data.id) {
                state.products[key].count -= data.count
            }
        }
    },

    setCountInc(state, data) {
        for (let key in state.products) {
            if (state.products[key].id == data.id) {
                state.products[key].count += data.count
            }
        }
    },

    changeProductId(state, data) {
        for (let key in state.products) {
            if (state.products[key].id == data.id) {
                state.products[key] = {
                    ...state.products[key],
                    ...data
                }
            }
        }
    }

};

const actions = {

    changeCount(context, data) {
        context.commit('changeCountMut', data)
    },

    fetchCatalog(context, data) {
        axios.post(config.api + 'products', {
                category_id: data.category,
                offset: 0,
                count: data.limit || 50
            })
            .then(response => {
                context.commit('setCatalog', response.data)
            })
            .catch(error => {
                errorHandler(error)
                context.commit('setCatalog', {})
            })

    },

    findInCatalog(context, data) {
        axios.post(config.api + 'search', {
                category_id: data.category,
                name: data.name,
            })
            .then(response => {
                context.commit('setCatalog', response.data)
            })
            .catch(() => {
                context.commit('setCatalog', {})
            })

    },

    productCountDec(context, data) {
        context.commit('setCountDec', data)
    },

    productCountInc(context, data) {
        context.commit('setCountInc', data)
    },

    changeProduct(context, data) {
        axios.post(config.api + 'change-product', data)
            .then(() => {

                data.onload()
                data.onload = ''
                context.commit('changeProductId', data)

            })
            .catch((error) => {

                console.error(error)
                alert('Ошибка обновления товара')
            })
    },

    createProduct(context, data) {
        axios.post(config.api + 'add-product', data)
            .then(() => {

                data.onload()
                context.commit('changeProductId', data)

            })
            .catch((error) => {
                console.error(error)
                alert('Ошибка обновления товара')
            })
    },

    getProductId(context, id) {
        return context.state.products.find(item => item.id == id)
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};